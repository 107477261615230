const blocksNames = {
    // Портфель - Метрика
    accountInfo: {
        account_invested: {
            name: 'Инвестировано',
            //synonim: 'instrument_ticker',
            helper: '',
            //disabled: true,
            //hideDefault: true,
        },
        account_current_value: {
            name: 'Текущая стоимость брокерского счета',
            helper: '',
        },
        account_free_cash: {
            name: 'Свободные денежные средства',
            helper: '',
        },
        account_total_value: {
            name: 'Текущая суммарная стоимость ЦБ на БС',
            helper: '',
        },
        account_security_payments: {
            name: 'Доход 1. Дивиденды / купоны за всю историю',
            helper: '',
        },
        account_security_payments_full_aci: {
            name: 'Доход 1. Дивиденды / купоны за всю историю с учетом тек. НКД',
            helper: '',
        },
        account_current_profit: {
            name: 'Доход 2. Прибыль по ЦБ текущая',
            helper: '',
        },
        account_security_payments_sum: {
            name: 'Доход 2. Прибыль по всем ЦБ за всю историю',
            helper: '',
        },
        account_assets_total_profit: {
            name: 'Общий доход ЦБ за всю историю',
            helper: '',
        },
        account_total_profit: {
            name: 'Общий доход БС за всю историю',
            helper: '',
            disabled: true,
            hide: true,
            hideDefault: true,
        },
        account_amortization_sum: {
            name: 'Амортизация за всю историю',
            helper: '',
        },
        account_plan_security_payments: {
            name: 'Доход 1. Купоны за 12 мес. вперед. ПЛАН',
            helper: '',
        },
        account_planed_bond_close_income: {
            name: 'Доход 2. План при погашении облигации',
            helper: '',
        },
        account_planed_bond_repayment_sum: {
            name: 'Сумма при погашении',
            helper: '',
        },
        account_info: {
            name: 'Дата последней сделки и комментарий к счету',
            helper: '',
        },
        account_assets_average_annual_income: {
            name: 'Среднегодовая прибыль ЦБ на БС',
            //synonim: 'instrument_ticker',
            helper: '',
            disabled: true,
            hide: true,
            hideDefault: true,
        },
    },
    // Все портфели - Метрика
    accountsInfo: {
        accounts_invested: {
            name: 'Инвестировано',
            //synonim: 'instrument_ticker',
            helper: '',
            //disabled: true,
            //hideDefault: true,
        },
        accounts_current_value: {
            name: 'Текущая стоимость всех брокерских счетов',
            helper: '',
        },
        accounts_free_cash: {
            name: 'Свободные денежные средства',
            helper: '',
        },
        accounts_total_value: {
            name: 'Текущая суммарная стоимость ЦБ на всех БС',
            helper: '',
        },
        accounts_security_payments: {
            name: 'Доход 1. Дивиденды / купоны за всю историю',
            helper: '',
        },
        accounts_current_profit: {
            name: 'Доход 2. Прибыль по ЦБ текущая',
            helper: '',
        },
        accounts_security_payments_sum: {
            name: 'Доход 2. Прибыль по всем ЦБ за всю историю',
            helper: '',
        },
        accounts_assets_total_profit: {
            name: 'Общий доход ЦБ за всю историю',
            helper: '',
        },
        accounts_total_profit: {
            name: 'Общий доход БС за всю историю',
            helper: '',
            disabled: true,
            hide: true,
            hideDefault: true,
        },
        accounts_amortization_sum: {
            name: 'Амортизация за всю историю',
            helper: '',
        },
        accounts_assets_average_annual_income: {
            name: 'Среднегодовая прибыль ЦБ на БС',
            helper: '',
            disabled: true,
            hide: true,
            hideDefault: true,
        },
    },
    // Вотчлист - Метрика
    couponsFutureInfo: {
        coupons_future_coupon_income: {
            name: 'Доход 1. Купоны за 12 мес. вперед. ПЛАН',
            //synonim: 'instrument_ticker',
            helper: '',
            //disabled: true,
            //hideDefault: true,
        },
        coupons_future_close_income: {
            name: 'Доход 2. ПЛАН при погашении облигации',
            helper: '',
        },
        coupons_future_repayment_sum: {
            name: 'Сумма при погашении',
            helper: '',
        },
        coupons_future_amortization_amount: {
            name: 'Амортизации / Погашения',
            helper: '',
        },
        coupons_future_average_year_income_percent: {
            name: 'Доход 1. Ср. % год. ПЛАН',
            helper: '',
        },
    },
    // Вотчлист - Метрика
    watchlistInfo: {
        watchlist_coupon_income: {
            name: 'Доход 1. ПЛАН. Купоны / Амортизация',
            //synonim: 'instrument_ticker',
            helper: '',
            //disabled: true,
            //hideDefault: true,
        },
        watchlist_average_year_income_percent: {
            name: 'Доход 1. ПЛАН. Средний % год',
            helper: '',
        },
        watchlist_close_income: {
            name: 'Доход 2. ПЛАН',
            helper: '',
        },
        watchlist_purchase_amount: {
            name: 'Сумма покупки',
            helper: '',
        },
        watchlist_repayment_sum: {
            name: 'Сумма при погашении',
            helper: '',
        },
        watchlist_average_ytm_percent: {
            name: 'Доход к погашению (YTM), %',
            helper: '',
        },
        watchlist_resident_tax_amount: {
            name: 'Налог с купонов на конец года',
            helper: '',
        },
    }
};

export {
    blocksNames,
};