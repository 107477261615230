<template>
    <b-card
        class="bond-screener__filters mb-5 shadow"
        bodyClass="row"
    >
        <template v-if="$store.state.mode === 'all'">
            <pre>{{ formData }}</pre>
        </template>
        <div
            v-if="userSettings"
            class="bond-screener__filters-presets col-12"
        >
            <b-dropdown
                variant="light"
                class="bond-screener__filters-presets-select"
                toggleClass="shadow"
                menuClass="mt-2"
                no-caret
            >
                <template #button-content>
                    <span>{{ selectedFilterKey ? userSettings?.filters?.watchList?.[selectedFilterKey]?.name : 'Выбрать или создать фильтр' }}</span>
                    <b-icon-chevron-down class="ms-1" />
                </template>
                <b-dropdown-item-button
                    :active="!selectedFilterKey"
                    @click.prevent="clearFilterTableData"
                >
                    Фильтр по умолчанию
                </b-dropdown-item-button>
                <b-dropdown-item-button
                    variant=""
                    @click.prevent="showFilterAdd()"
                >
                    Создать новый фильтр...
                </b-dropdown-item-button>
                <b-dropdown-divider v-if="Object.keys(userSettings?.filters?.watchList || {}).length" />
                <b-dropdown-item-button
                    v-for="(item, key) in userSettings?.filters?.watchList"
                    :key="`filter-${key}`"
                    :active="+selectedFilterKey === +key"
                    @click.prevent="selectFilter(key)"
                >
                    {{ item.name }}
                </b-dropdown-item-button>
            </b-dropdown>
            <b-button
                v-if="selectedFilterKey && canSaveFilter"
                variant="light-primary"
                class="me-3 shadow"
                size=""
                pill
                @click.stop="saveFilter"
            >
                <b-icon-floppy class="me-1" />
                Сохранить изменения
            </b-button>
            <a
                v-if="selectedFilterKey"
                href="#"
                class="m--underline"
                @click.prevent="showFilterDelete()"
            >
                <b-icon-x-lg class="me-1 text-danger" />Удалить
            </a>
            <b-button
                variant="light-primary"
                class="ms-auto shadow"
                size=""
                pill
                @click.prevent="toggleShowFilters"
            >
                {{ showFilters ? 'Скрыть параметры поиска' : 'Показать параметры поиска' }}
            </b-button>

        </div>
        <template v-if="showFilters">
            <div class="col-12 mt-2">
                <hr>
            </div>
            <div class="col-12 col-xxl-5 mb-auto">
                <div class="row">
                    <div class="col-6">
                        <b-form-group
                            id="input-group-price"
                            label="Цена текущая, ₽"
                            class="mt-auto"
                        >
                            <div class="row">
                                <div class="col-6 pe-2">
                                    <input
                                        id="input-price"
                                        v-maska
                                        data-maska="0.99"
                                        data-maska-tokens="0:\d:multiple|9:\d:optional"
                                        v-model="formData.price_min"
                                        type="text"
                                        placeholder="от"
                                        class="form-control form-control-sm shadow-sm m--no-pill"
                                    />
                                </div>
                                <div class="col-6 ps-1 pe-3">
                                    <input
                                        id="input-price"
                                        v-maska
                                        data-maska="0.99"
                                        data-maska-tokens="0:\d:multiple|9:\d:optional"
                                        v-model="formData.price_max"
                                        type="text"
                                        placeholder="до"
                                        class="form-control form-control-sm shadow-sm m--no-pill"
                                    />
                                </div>
                            </div>
                        </b-form-group>
                        <b-form-group
                            id="input-group-year-income"
                            class="mt-auto"
                        >
                            <template #label>
                                Доход 1, %
                                <Helper
                                    id="popover-watchlist-input-year-income"
                                    helper="watchlist_input_year_income"
                                    classModifier="m--dark"
                                />
                            </template>
                            <div class="row">
                                <div class="col-6 pe-2">
                                    <input
                                        id="input-year-income"
                                        v-maska
                                        data-maska="0.99"
                                        data-maska-tokens="0:\d:multiple|9:\d:optional"
                                        v-model="formData.year_income_min"
                                        type="text"
                                        placeholder="от"
                                        class="form-control form-control-sm shadow-sm m--no-pill"
                                    />
                                </div>
                                <div class="col-6 ps-1 pe-3">
                                    <input
                                        id="input-year-income-max"
                                        v-maska
                                        data-maska="0.99"
                                        data-maska-tokens="0:\d:multiple|9:\d:optional"
                                        v-model="formData.year_income_max"
                                        type="text"
                                        placeholder="до"
                                        class="form-control form-control-sm shadow-sm m--no-pill"
                                    />
                                </div>
                            </div>
                        </b-form-group>

                        <b-form-group
                            id="input-group-close-income"
                            class="mt-auto"
                        >
                            <template #label>
                                Доход 2, %
                                <Helper
                                    id="popover-watchlist-input-close-income"
                                    helper="watchlist_input_close_income"
                                    classModifier="m--dark"
                                />
                            </template>
                            <div class="row">
                                <div class="col-6 pe-2">
                                    <input
                                        id="input-close-income"
                                        v-maska
                                        data-maska="0.99"
                                        data-maska-tokens="0:\d:multiple|9:\d:optional"
                                        v-model="formData.close_income_min"
                                        type="text"
                                        placeholder="от"
                                        class="form-control form-control-sm shadow-sm m--no-pill"
                                    />
                                </div>
                                <div class="col-6 ps-1 pe-3">
                                    <input
                                        id="input-close-income-max"
                                        v-maska
                                        data-maska="0.99"
                                        data-maska-tokens="0:\d:multiple|9:\d:optional"
                                        v-model="formData.close_income_max"
                                        type="text"
                                        placeholder="до"
                                        class="form-control form-control-sm shadow-sm m--no-pill"
                                    />
                                </div>
                            </div>
                        </b-form-group>
                        <b-form-group
                            id="input-group-total-income"
                            class="mt-auto"
                        >
                            <template #label>
                                Общий доход, %
                                <Helper
                                    id="popover-watchlist-input-total-income"
                                    helper="watchlist_input_total_income"
                                    classModifier="m--dark"
                                />
                            </template>
                            <div class="row">
                                <div class="col-6 pe-2">
                                    <input
                                        id="input-total-income"
                                        v-maska
                                        data-maska="0.99"
                                        data-maska-tokens="0:\d:multiple|9:\d:optional"
                                        v-model="formData.total_income_min"
                                        type="text"
                                        placeholder="от"
                                        class="form-control form-control-sm shadow-sm m--no-pill"
                                    />
                                </div>
                                <div class="col-6 ps-1 pe-3">
                                    <input
                                        id="input-total-income-max"
                                        v-maska
                                        data-maska="0.99"
                                        data-maska-tokens="0:\d:multiple|9:\d:optional"
                                        v-model="formData.total_income_max"
                                        type="text"
                                        placeholder="до"
                                        class="form-control form-control-sm shadow-sm m--no-pill"
                                    />
                                </div>
                            </div>
                        </b-form-group>
                    </div>

                    <div class="col-6">
                        <b-form-group
                            id="input-group-maturity-years"
                            label="Срок до погашения, лет"
                            class="mt-auto"
                        >
                            <div class="row">
                                <div class="col-6 pe-2">
                                    <input
                                        id="input-maturity-years"
                                        v-maska
                                        data-maska="0.99"
                                        data-maska-tokens="0:\d:multiple|9:\d:optional"
                                        v-model="formData.maturity_years_min"
                                        type="text"
                                        placeholder="от"
                                        class="form-control form-control-sm shadow-sm m--no-pill"
                                    />
                                </div>
                                <div class="col-6 ps-1 pe-3">
                                    <input
                                        id="input-maturity-years-max"
                                        v-maska
                                        data-maska="0.99"
                                        data-maska-tokens="0:\d:multiple|9:\d:optional"
                                        v-model="formData.maturity_years_max"
                                        type="text"
                                        placeholder="до"
                                        class="form-control form-control-sm shadow-sm m--no-pill"
                                    />
                                </div>
                            </div>
                        </b-form-group>

                        <b-form-group
                            id="input-group-coupon-days-next"
                            label="Дней до выплаты купона"
                            class="mt-auto"
                        >
                            <div class="row">
                                <div class="col-6 pe-2">
                                    <input
                                        id="input-coupon-days-next"
                                        v-maska
                                        data-maska="0"
                                        data-maska-tokens="0:\d:multiple"
                                        v-model="formData.coupon_days_until_next_min"
                                        type="text"
                                        placeholder="от"
                                        class="form-control form-control-sm shadow-sm m--no-pill"
                                    />
                                </div>
                                <div class="col-6 ps-1 pe-3">
                                    <input
                                        id="input-coupon-days-next-max"
                                        v-maska
                                        data-maska="0"
                                        data-maska-tokens="0:\d:multiple"
                                        v-model="formData.coupon_days_until_next_max"
                                        type="text"
                                        placeholder="до"
                                        class="form-control form-control-sm shadow-sm m--no-pill"
                                    />
                                </div>
                            </div>
                        </b-form-group>

                        <b-form-group
                            id="input-group-coupon-days-after"
                            label="Дней после выплаты купона"
                            class="mt-auto"
                        >
                            <div class="row">
                                <div class="col-6 pe-2">
                                    <input
                                        id="input-coupon-days-after"
                                        v-maska
                                        data-maska="0"
                                        data-maska-tokens="0:\d:multiple"
                                        v-model="formData.coupon_days_after_min"
                                        type="text"
                                        placeholder="от"
                                        class="form-control form-control-sm shadow-sm m--no-pill"
                                    />
                                </div>
                                <div class="col-6 ps-1 pe-3">
                                    <input
                                        id="input-coupon-days-after-max"
                                        v-maska
                                        data-maska="0"
                                        data-maska-tokens="0:\d:multiple"
                                        v-model="formData.coupon_days_after_max"
                                        type="text"
                                        placeholder="до"
                                        class="form-control form-control-sm shadow-sm m--no-pill"
                                    />
                                </div>
                            </div>
                        </b-form-group>

                        <b-form-group
                            id="input-group-coupon-frequency"
                            label="Частота выплаты купонов в год"
                            class="mt-auto"
                        >
                            <div class="row">
                                <div class="col-6 pe-2">
                                    <input
                                        id="input-coupon-frequency"
                                        v-maska
                                        data-maska="0.99"
                                        data-maska-tokens="0:\d:multiple|9:\d:optional"
                                        v-model="formData.coupon_frequency_min"
                                        type="text"
                                        placeholder="от"
                                        class="form-control form-control-sm shadow-sm m--no-pill"
                                    />
                                </div>
                                <div class="col-6 ps-1 pe-3">
                                    <input
                                        id="input-coupon-frequency-max"
                                        v-maska
                                        data-maska="0.99"
                                        data-maska-tokens="0:\d:multiple|9:\d:optional"
                                        v-model="formData.coupon_frequency_max"
                                        type="text"
                                        placeholder="до"
                                        class="form-control form-control-sm shadow-sm m--no-pill"
                                    />
                                </div>
                            </div>
                        </b-form-group>
                    </div>

                    <b-form-group
                        id="input-group-minimum-coupon-rate"
                        class="col-6 mt-auto pe-3"
                    >
                        <template #label>
                            Мин. ставка ПК 1, %
                            <Helper
                                id="popover-screener-input-minimum-coupon-rate"
                                helper="screener_input_minimum_coupon_rate"
                                classModifier="m--dark"
                            />
                        </template>
                        <input
                            id="input-minimum-coupon-rate"
                            v-maska
                            data-maska="0.99"
                            data-maska-tokens="0:\d:multiple|9:\d:optional"
                            v-model="formData.minimum_coupon_rate"
                            type="text"
                            :disabled="!formData.floater_type?.length || formData.floater_type?.indexOf('known') === -1"
                            placeholder="от"
                            class="form-control form-control-sm shadow-sm m--no-pill"
                        />
                    </b-form-group>

                    <b-form-group
                        id="input-group-spread-amount"
                        class="col-6 mt-auto"
                    >
                        <template #label>
                            Спред ПК 3, %
                            <Helper
                                id="popover-screener-input-spread-amount"
                                helper="screener_input_spread_amount"
                                classModifier="m--dark"
                            />
                        </template>
                        <div class="row">
                            <div class="col-6 pe-2">
                                <input
                                    id="input-spread-amount"
                                    v-maska
                                    data-maska="0.99"
                                    data-maska-tokens="0:\d:multiple|9:\d:optional"
                                    v-model="formData.spread_amount_min"
                                    type="text"
                                    :disabled="!formData.floater_type?.length || formData.floater_type?.indexOf('spreading') === -1"
                                    placeholder="от"
                                    class="form-control form-control-sm shadow-sm m--no-pill"
                                />
                            </div>
                            <div class="col-6 ps-1 pe-3">
                                <input
                                    id="input-spread-amount-max"
                                    v-maska
                                    data-maska="0.99"
                                    data-maska-tokens="0:\d:multiple|9:\d:optional"
                                    v-model="formData.spread_amount_max"
                                    type="text"
                                    :disabled="!formData.floater_type?.length || formData.floater_type?.indexOf('spreading') === -1"
                                    placeholder="до"
                                    class="form-control form-control-sm shadow-sm m--no-pill"
                                />
                            </div>
                        </div>
                    </b-form-group>
                    <b-form-group
                        id="input-group-rating"
                        class="col-12 mt-auto"
                    >
                        <template #label>
                            Рейтинг эмитента
                            <Helper
                                id="popover-watchlist-input-rating"
                                helper="watchlist_input_rating"
                                classModifier="m--dark"
                            />
                        </template>
                        <b-form-checkbox-group
                            id="checkbox-rating"
                            v-model="formData.rating"
                            name="rating"
                            :options="ratingList"
                            class="ps-1"
                        />
                    </b-form-group>
                </div>
            </div>

            <div class="col-12 col-lg-6 col-xxl mb-auto">
                <div class="row">

                    <b-form-group
                        id="input-group-emitter"
                        label="Название компании"
                        class="mt-auto"
                    >
                        <div class="pe-1">
                            <Multiselect
                                id="input-emitter"
                                v-model="formData.emitter"
                                :options="listEmitterList"
                                mode="single"
                                :close-on-select="true"
                                :searchable="true"
                                :create-option="false"
                                class="shadow-sm m--no-pill"
                                placeholder=""
                                noOptionsText="Список пуст"
                                noResultsText="По Вашему запросу ничего не найдено"
                            />
                        </div>
                    </b-form-group>

                    <b-form-group
                        id="input-group-branch"
                        label="Отрасль"
                        class="mt-auto"
                    >
                        <div class="pe-1">
                            <Multiselect
                                id="input-branch"
                                v-model="formData.branch"
                                :options="listBranchList"
                                mode="single"
                                :close-on-select="true"
                                :searchable="true"
                                :create-option="false"
                                class="shadow-sm m--no-pill"
                                placeholder=""
                                noOptionsText="Список пуст"
                                noResultsText="По Вашему запросу ничего не найдено"
                            />
                        </div>
                    </b-form-group>

                    <b-form-group
                        id="input-group-foundation-year"
                        label="Год основания эмитента"
                        class="mt-auto"
                    >
                        <div class="row">
                            <div class="col-6 pe-2">
                                <input
                                    id="input-foundation-year"
                                    v-maska
                                    data-maska="0.99"
                                    data-maska-tokens="0:\d:multiple|9:\d:optional"
                                    v-model="formData.foundation_year_min"
                                    type="text"
                                    placeholder="от"
                                    class="form-control form-control-sm shadow-sm m--no-pill"
                                />
                            </div>
                            <div class="col-6 ps-1 pe-3">
                                <input
                                    id="input-foundation-year-max"
                                    v-maska
                                    data-maska="0.99"
                                    data-maska-tokens="0:\d:multiple|9:\d:optional"
                                    v-model="formData.foundation_year_max"
                                    type="text"
                                    placeholder="до"
                                    class="form-control form-control-sm shadow-sm m--no-pill"
                                />
                            </div>
                        </div>
                    </b-form-group>

                </div>
            </div>
            <div class="col-12 col-lg-6 col-xxl-2 mb-auto">
                <div class="row">
                    <b-form-group
                        label="Особенности номинала"
                    >
                        <!--div class="mb-2">
                            <b-form-checkbox
                                v-model="formData.feature_all"
                                :value="true"
                                switch
                                @change="selectFeatureAll"
                            >
                                Выбрать все
                            </b-form-checkbox>
                        </div-->
                        <b-form-checkbox-group
                            id="checkbox-nominal-feature"
                            v-model="formData.nominal_feature"
                            name="nominal_feature"
                            :options="listNominalFeatureList"
                            stacked
                            @change="selectFeature"
                        />
                    </b-form-group>
                    <b-form-group
                        label="Особенности купона"
                    >
                        <!--div class="mb-2">
                            <b-form-checkbox
                                v-model="formData.feature_all"
                                :value="true"
                                switch
                                @change="selectFeatureAll"
                            >
                                Выбрать все
                            </b-form-checkbox>
                        </div-->
                        <b-form-checkbox-group
                            id="checkbox-coupon-feature"
                            v-model="formData.coupon_feature"
                            name="coupon_feature"
                            :options="listCouponFeatureList"
                            stacked
                            @change="selectFeature('coupon')"
                        />
                        <b-form-checkbox-group
                            id="checkbox-coupon-floating"
                            v-model="formData.floater_type"
                            name="coupon_feature"
                            :options="floaterTypeList"
                            :disabled="!formData.coupon_feature || formData.coupon_feature?.indexOf('floating') === -1"
                            stacked
                            @change="selectFeature('floater')"
                        />
                    </b-form-group>
                </div>
            </div>

            <div class="col-12 col-xxl-2 mb-auto">
                <div class="row">
                    <b-form-group
                        id="input-group-tax"
                        class="mt-auto"
                    >
                        <template #label>
                            Налоговая ставка, %
                            <Helper
                                id="popover-watchlist-input-tax"
                                helper="watchlist_input_tax"
                                classModifier="m--dark"
                            />
                        </template>
                        <div class="pe-1">
                            <input
                                id="input-tax"
                                v-maska
                                data-maska="00"
                                data-maska-tokens="0:\d"
                                v-model="formData.tax"
                                type="text"
                                placeholder=""
                                class="form-control form-control-sm shadow-sm m--no-pill"
                            />
                        </div>
                    </b-form-group>

                    <b-form-group>
                        <template #label>
                            Дефолт
                            <Helper
                                id="popover-watchlist-checkbox-has-default"
                                helper="watchlist_checkbox_has_default"
                                classModifier="m--dark"
                            />
                        </template>
                        <div class="mb-2">
                            <b-form-checkbox
                                v-model="formData.has_default_all"
                                :value="true"
                                switch
                                @change="selectHasDefaultAll"
                            >
                                Выбрать все
                            </b-form-checkbox>
                        </div>
                        <b-form-checkbox-group
                            id="checkbox-type"
                            v-model="formData.has_default"
                            name="has_default"
                            :options="hasDefaultList"
                            stacked
                            @change="selectHasDefault"
                        />
                    </b-form-group>
                </div>
            </div>
            <div class="col-12 pt-2">
                <b-button
                    variant="primary"
                    class="col-auto me-3"
                    size=""
                    pill
                    @click.stop="changeFilterTableData"
                >
                    Применить фильтр
                </b-button>
                <b-button
                    v-if="useFilters"
                    variant="outline-primary"
                    class="col-auto"
                    size=""
                    pill
                    @click.stop="clearFilterTableData"
                >
                    Сбросить фильтр
                </b-button>
            </div>
        </template>
        <ModalFilterAdd
            :show="showModalFilterAdd"
            filterInterface="watchList"
            :filterData="formData"
            @hideModal="hideFilterAdd"
        />
        <ModalFilterDelete
            :show="showModalFilterDelete"
            filterInterface="watchList"
            :selectedFilterKey="selectedFilterKey"
            :filterData="formData"
            @hideModal="hideFilterDelete"
        />
    </b-card>
</template>

<script>
    import { app } from "@/services";
    import Multiselect from '@vueform/multiselect'
    import { defaultWatchListFilter } from "@/settings/tables";
    import Helper from "@/components/helper";
    import ModalFilterAdd from "@/components/modals/modal-filter-add";
    import ModalFilterDelete from "@/components/modals/modal-filter-delete";

    export default {
        emits: ['changeFilterTableData', 'clearFilterTableData'],
        components: {
            Helper,
            Multiselect,
            ModalFilterAdd,
            ModalFilterDelete,
        },
        props: {
            watchlistId: {
                type: [Number, String],
                default() { return null; }
            },
            classButton: {
                type: String,
                default() { return null; }
            },
            tableHeader: {
                type: Object,
                default() { return {}; }
            },
            tableHeaderGroups: {
                type: Array,
                default() { return []; }
            },
            currentFilterKey: {
                type: [String, Number],
                default() { return null; }
            },
            tableDataFilters: {
                type: Object,
                default() { return {}; }
            },
            useFilters: {
                type: Boolean,
                default() { return false; }
            },
        },
        data() {
            return {
                showFilters: false,
                listCurrencyList: [],
                listBranchList: [],
                listEmitterList: [],
                listNominalFeatureList: [],
                listCouponFeatureList: [],
                listTypeList: [],
                ratingList: [],
                hasDefaultList: [],
                floaterTypeList: [],
                defaultWatchListFilter: defaultWatchListFilter,
                selectedFilterKey: this.currentFilterKey,
                canSaveFilter: false,
                formData: this.tableDataFilters,
                showModalFilterAdd: false,
                showModalFilterDelete: false,
                showLoaderSending: false
            }
        },
        computed: {
            userSettings() {
                return this.$store.state.user?.user_settings || {};
            },
        },
        watch: {
            useFilters: {
                //immediate: true,
                handler() {
                    if (!this.useFilters) {
                        //this.selectCurrencyAll();
                        //this.selectFeatureAll();
                        this.selectTypeAll();
                        this.selectHasDefaultAll();
                        this.formData = this.tableDataFilters;
                    }
                }
            },
            formData: {
                //immediate: true,
                deep: true,
                handler() {
                    console.log('formData');
                    this.canSaveFilter = true;
                }
            },
        },
        mounted() {
            //this.selectCurrencyAll();
            //this.getCurrencyList();
            this.getNominalFeatureList();
            this.getCouponFeatureList();
            this.getBranchList();
            this.getEmitterList();
            this.getRatingList();
            this.getFloaterTypeList();
            this.getHasDefaultList();
            //this.getTypeList();
        },
        methods: {
            getCurrencyList() {
                this.showLoaderSending = true;
                app.getWatchListCurrencies(this.watchlistId).then(res => {
                    if (!res.error) this.listCurrencyList = res.map(item => { return { text: item.name, value: item.key } });
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                    this.listCurrencyList = [];
                });
            },
            getBranchList() {
                this.showLoaderSending = true;
                app.getWatchListBranches(this.watchlistId).then(res => {
                    if (!res.error) this.listBranchList = res.map(item => { return { label: item.name, value: item.key } });
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                    this.listBranchList = [];
                });
            },
            getEmitterList() {
                this.showLoaderSending = true;
                app.getWatchListEmitters(this.watchlistId).then(res => {
                    if (!res.error) this.listEmitterList = res.map(item => { return { label: item.name, value: item.key } });
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                    this.listEmitterList = [];
                });
            },
            getNominalFeatureList() {
                this.showLoaderSending = true;
                app.getWatchListNominalFeatures(this.watchlistId).then(res => {
                    if (!res.error) this.listNominalFeatureList = res.map(item => { return { text: item.name, value: item.key } });
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                    this.listNominalFeatureList = [];
                });
            },
            getCouponFeatureList() {
                this.showLoaderSending = true;
                app.getWatchListCouponFeatures(this.watchlistId).then(res => {
                    if (!res.error) this.listCouponFeatureList = res.map(item => { return { text: item.name, value: item.key } });
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                    this.listCouponFeatureList = [];
                });
            },
            getTypeList() {
                this.showLoaderSending = true;
                app.getWatchListTypes(this.watchlistId).then(res => {
                    if (!res.error) this.listTypeList = res.map(item => { return { text: item.name, value: item.key } });
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                    this.listTypeList = [];
                });
            },
            getRatingList() {
                this.showLoaderSending = true;
                app.getBondRatings().then(res => {
                    if (!res.error) this.ratingList = res.map(item => { return { text: item.name, value: item.key } });
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                    this.bondTypeList = [];
                });
            },
            getHasDefaultList() {
                this.showLoaderSending = true;
                app.getBondHasDefault().then(res => {
                    if (!res.error) this.hasDefaultList = res.map(item => { return { text: item.name, value: item.key } });
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                    this.bondTypeList = [];
                });
            },
            getFloaterTypeList() {
                this.showLoaderSending = true;
                app.getBondFloaterTypes().then(res => {
                    if (!res.error) this.floaterTypeList = res.map(item => { return { text: item.name, value: item.key } });
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                    this.floaterTypeList = [];
                });
            },
            toggleShowFilters() {
                this.showFilters = !this.showFilters;
            },
            selectCurrencyAll() {
                this.formData.currency_all ? this.formData.currency = this.listCurrencyList.map(item => { return item.value; }) : delete this.formData.currency;
            },
            selectCurrency() {
                //this.formData.currency_all = (this.formData.currency.length === this.bondCurrencyList.length) ? true : false;
            },
            selectFeatureAll() {
                this.formData.feature_all ? this.formData.feature = this.listFeatureList.map(item => { return item.value; }) : delete this.formData.feature;
            },
            selectFeature(type = null) {
                if (type === 'coupon') {
                    if (this.formData.coupon_feature.indexOf('floating') === -1) delete this.formData.floater_type;
                    if (this.formData.coupon_feature.indexOf('fixed') !== -1) delete this.formData.floater_type;
                }
                if (type === 'floater') {
                    if (this.formData.floater_type?.length) {
                        let idx = this.formData.coupon_feature.indexOf('fixed')
                        if (idx !== -1) this.formData.coupon_feature.splice(idx, 1)
                    }
                }
                //this.formData.feature_all = (this.formData.feature.length === this.bondFeatureList.length) ? true : false;
            },
            selectTypeAll() {
                this.formData.security_type_all ? this.formData.security_type = this.listTypeList.map(item => { return item.value; }) : delete this.formData.security_type;
            },
            selectType() {
                this.formData.security_type_all = (this.formData.security_type.length === this.listTypeList.length) ? true : false;
            },
            selectHasDefaultAll() {
                this.formData.has_default_all ? this.formData.has_default = this.hasDefaultList.map(item => { return item.value; }) : delete this.formData.has_default;
            },
            selectHasDefault() {
                this.formData.has_default_all = (this.formData.has_default.length === this.hasDefaultList.length) ? true : false;
            },
            changeFilterTableData() {
                this.$emit('changeFilterTableData', this.formData);
            },
            clearFilterTableData() {
                this.selectedFilterKey = null;
                let settings = this.userSettings;
                delete settings.filters?.watchList?.current;
                this.showLoaderSending = true;
                app.updateUser({ user_settings: settings }).then(res => {
                    delete res.currencies;
                    this.$store.dispatch('setUser', res);
                    this.showLoaderSending = false;
                }).catch(err => {
                    console.error(err);
                    this.showLoaderSending = false;
                });
                this.canSaveFilter = false;
                this.$emit('clearFilterTableData');
            },
            selectFilter(key) {
                this.selectedFilterKey = key;
                this.formData = this.userSettings?.filters?.watchList[key]?.filters;
                let settings = this.userSettings;
                settings.filters.watchList.current = key;
                this.showLoaderSending = true;
                app.updateUser({ user_settings: settings }).then(res => {
                    delete res.currencies;
                    this.$store.dispatch('setUser', res);
                    this.showLoaderSending = false;
                }).catch(err => {
                    console.error(err);
                    this.showLoaderSending = false;
                });
                this.changeFilterTableData();
                this.$nextTick(() => {
                    this.canSaveFilter = false;
                });
            },
            saveFilter() {
                if (this.selectedFilterKey) {
                    let settings = this.userSettings;
                    settings.filters.watchList[this.selectedFilterKey].filters = this.formData;
                    this.showLoaderSending = true;
                    app.updateUser({ user_settings: settings }).then(res => {
                        delete res.currencies;
                        this.$store.dispatch('setUser', res);
                        this.showLoaderSending = false;
                    }).catch(err => {
                        console.error(err);
                        this.showLoaderSending = false;
                    });
                    this.canSaveFilter = false;
                }
            },
            showFilterAdd() {
                this.showModalFilterAdd = true;
            },
            hideFilterAdd(filterKey) {
                this.showModalFilterAdd = false;
                if (filterKey) {
                    this.showFilters = true;
                    this.selectFilter(filterKey);
                }
            },
            showFilterDelete() {
                this.showModalFilterDelete = true;
            },
            hideFilterDelete(refreshData) {
                this.showModalFilterDelete = false;
                if (refreshData) {
                    this.selectedFilterKey = null;
                    this.formData = this.defaultWatchListFilter;
                    this.clearFilterTableData();
                }
            },
        }
    };
</script>
