<template>
    <template v-if="showLoaderSending">
        <div class="app__loader">
            <b-spinner
                variant="success"
                class="me-3"
            />
            <span class="text-success">Загрузка данных ...</span>
        </div>
    </template>
    <template v-else>
        <div class="d-flex account__buttons align-items-center mb-3">
            <div class="h2">Метрики брокерского счета</div>
            <b-button
                variant="light"
                :class="['shadow account__buttons-circle ms-auto']"
                pill
                @click.stop="showFilterBlocks()"
            >
                <b-icon-sliders2-vertical></b-icon-sliders2-vertical>
            </b-button>
            <ModalFilterBlocks
                :show="showModalFilterBlocks"
                :items="blocksNames"
                :itemsFiltered="blocksFilters"
                :minCols="1"
                @changeFilter="changeFilterBlocks"
                @hideModal="hideFilterBlocks"
            />
        </div>
        <div class="account__info-block row mb-5 pb-2">
            <div
                v-if="blocksFilters.indexOf('account_invested') !== -1"
                class="account__info-block-item col-12 col-md-8 col-lg-6 col-xl-4 col-xxl-3 mb-4"
            >
                <b-card
                    class="shadow h-100"
                    :bodyClass="`account__info-block-item-inner`"
                >
                    <div class="content pe-4 m--title">
                        Внесено лично
                    </div>
                    <div class="d-flex account__info-block-item-title">
                        <span class="h5 mb-2">{{ $helpers.toPrice(infoAccountParam('deposited'), { sign: currencyCurrent.symbol, pointer: ',' }) }}</span>
                    </div>
                    <div class="content pe-4 m--title">
                        Выведено лично
                    </div>
                    <div class="d-flex account__info-block-item-title">
                        <span class="h5 mb-2">{{ $helpers.toPrice(infoAccountParam('withdrawn'), { sign: currencyCurrent.symbol, pointer: ',' }) }}</span>
                    </div>
                    <div class="content pe-4 m--title">
                        Остаток внесенных лично
                    </div>
                    <div class="d-flex account__info-block-item-title">
                        <span class="h5 mb-2">{{ $helpers.toPrice(infoAccountParam('invested'), { sign: currencyCurrent.symbol, pointer: ',' }) }}</span>
                    </div>
                    <Helper
                        :id="`account_invested-${portfolio.id}`"
                        helper="account_invested"
                        classParent="account__info-block-item"
                    />
                </b-card>
            </div>
            <!--BlockInfo
                titles="['Внесено лично', Инвестировано']"
                :values="[infoAccountParam('deposited'), infoAccountParam('invested')]"
                :valuess="infoAccountParams('invested')"
                valueToPrice
                :showFull="currentFilter"
                classMain="account__info-block-item"
                classColor=""
                helper="account_invested"
            /-->
            <BlockInfo
                v-if="blocksFilters.indexOf('account_current_value') !== -1"
                title="Текущая стоимость брокерского счета"
                :showFull="currentFilter"
                :value="infoAccountParam('portfolio_aci_sum')"
                :values="infoAccountParams('portfolio_aci_sum')"
                valueToPrice
                classMain="account__info-block-item"
                classColor=""
                helper="account_current_value"
            />
            <BlockInfo
                v-if="blocksFilters.indexOf('account_free_cash') !== -1"
                title="Свободные денежные средства"
                :value="infoAccountParam('cash_sum')"
                :values="infoAccountParams('cash_sum')"
                valueToPrice
                :showFull="currentFilter"
                classMain="account__info-block-item"
                classColor=""
                helper="account_free_cash"
            />
            <BlockInfo
                v-if="blocksFilters.indexOf('account_total_value') !== -1"
                title="Текущая стоимость ЦБ на БС"
                :showFull="currentFilter"
                :value="infoAccountParam('assets_aci_sum')"
                :values="infoAccountParams('assets_aci_sum')"
                valueToPrice
                classMain="account__info-block-item"
                classColor=""
                helper="account_total_value"
            />
            <BlockInfo
                v-if="blocksFilters.indexOf('account_security_payments') !== -1"
                title="Доход 1. Дивиденды / купоны за всю историю"
                :showFull="currentFilter"
                :value="infoAccountParam('security_payments_sum')"
                :values="infoAccountParams('security_payments_sum')"
                valueToPrice
                valueUseSign
                valueLabel="Получено"
                :valueSublabel="$helpers.toPrice(infoAccountParam('security_payments_percent'), { sign: '%', pointer: ',' })"
                :subvalue="infoAccountParam('security_payments_sum_payment_aci')"
                :subvalues="infoAccountParams('security_payments_sum_payment_aci')"
                subvalueUseSign
                subvalueToPrice
                subvalueLabel="Получено - НКД"
                :subvalueSublabel="$helpers.toPrice(infoAccountParam('security_payments_payment_aci_percent'), { sign: '%', pointer: ',' })"
                classMain="account__info-block-item"
                :classColor="`text-${infoAccountParam('security_payments_sum') >= 0 ? 'success' : 'danger'}`"
                helper="account_security_payments"
            />
            <BlockInfo
                v-if="blocksFilters.indexOf('account_security_payments_full_aci') !== -1"
                title="Доход 1. Дивиденды / купоны за всю историю с учетом тек. НКД"
                :showFull="currentFilter"
                :value="infoAccountParam('security_payments_sum_full_aci')"
                :values="infoAccountParams('security_payments_sum_full_aci')"
                valueToPrice
                valueUseSign
                :subvalue="infoAccountParam('security_payments_full_aci_percent')"
                :subvalues="infoAccountParams('security_payments_full_aci_percent')"
                subvalueToPercent
                classMain="account__info-block-item"
                :classColor="`text-${infoAccountParam('security_payments_sum_full_aci') >= 0 ? 'success' : 'danger'}`"
                helper="account_security_payments_sum_full_aci"
            />
            <BlockInfo
                v-if="blocksFilters.indexOf('account_current_profit') !== -1"
                title="Доход 2. Прибыль по ЦБ текущая"
                :showFull="currentFilter"
                :value="infoAccountParam('assets_income')"
                :values="infoAccountParams('assets_income')"
                valueToPrice
                valueUseSign
                :subvalue="infoAccountParam('assets_income_percent')"
                :subvalues="infoAccountParams('assets_income_percent')"
                subvalueToPercent
                classMain="account__info-block-item"
                :classColor="`text-${infoAccountParam('assets_income') >= 0 ? 'success' : 'danger'}`"
                helper="account_current_profit"
            />
            <BlockInfo
                v-if="blocksFilters.indexOf('account_security_payments_sum') !== -1"
                title="Доход 2. Прибыль по всем ЦБ за всю историю"
                :showFull="currentFilter"
                :value="infoAccountParam('income')"
                :values="infoAccountParams('income')"
                valueToPrice
                valueUseSign
                :subvalue="infoAccountParam('income_percent')"
                :subvalues="infoAccountParams('income_percent')"
                subvalueToPercent
                classMain="account__info-block-item"
                :classColor="`text-${infoAccountParam('income') >= 0 ? 'success' : 'danger'}`"
                helper="account_security_payments_sum"
            />
            <BlockInfo
                v-if="blocksFilters.indexOf('account_assets_total_profit') !== -1"
                title="Общий доход ЦБ за всю историю"
                :showFull="currentFilter"
                :value="infoAccountParam('assets_total_income_aci')"
                :values="infoAccountParams('assets_total_income_aci')"
                valueToPrice
                valueUseSign
                :subvalue="infoAccountParam('assets_total_income_aci_percent')"
                :subvalues="infoAccountParams('assets_total_income_aci_percent')"
                subvalueToPercent
                classMain="account__info-block-item"
                :classColor="`text-${infoAccountParam('assets_total_income_aci') >= 0 ? 'success' : 'danger'}`"
                helper="account_assets_total_profit"
            />
            <BlockInfo
                v-if="blocksFilters.indexOf('account_total_profit') !== -1"
                title="Общий доход БС за всю историю"
                :showFull="currentFilter"
                :value="infoAccountParam('total_income_aci')"
                :values="infoAccountParams('total_income_aci')"
                valueToPrice
                valueUseSign
                :subvalue="infoAccountParam('total_income_aci_percent')"
                :subvalues="infoAccountParams('total_income_aci_percent')"
                subvalueToPercent
                classMain="account__info-block-item"
                :classColor="`text-${infoAccountParam('total_income_aci') >= 0 ? 'success' : 'danger'}`"
                helper="account_total_profit"
            />
            <BlockInfo
                v-if="blocksFilters.indexOf('account_amortization_sum') !== -1"
                title="Амортизация за всю историю"
                :showFull="currentFilter"
                :value="infoAccountParam('amortization_sum')"
                :values="infoAccountParams('amortization_sum')"
                valueToPrice
                classMain="account__info-block-item"
                classColor=""
                helper="account_amortization_sum"
            />
            <!--BlockInfo
                title="Среднегодовая прибыль БС"
                :value="infoAccountParam('average_annual_income')"
                valueToPercent
                classMain="account__info-block-item"
                :classColorValue="`text-${infoAccountParam('average_annual_income') >= 0 ? 'success' : 'danger'}`"
                helper="account_average_annual_income"
            /-->
            <BlockInfo
                v-if="blocksFilters.indexOf('account_plan_security_payments') !== -1"
                title="Доход 1. Купоны за 12 мес. вперед. ПЛАН"
                :showFull="currentFilter"
                :value="infoAccountParam('planed_bond_year_income')"
                :values="infoAccountParams('planed_bond_year_income')"
                valueToPrice
                valueUseSign
                :subvalue="infoAccountParam('planed_bond_year_income_percent')"
                :subvalues="infoAccountParams('planed_bond_year_income_percent')"
                subvalueToPercent
                classMain="account__info-block-item"
                :classColor="`text-${infoAccountParam('planed_bond_year_income') >= 0 ? 'success' : 'danger'}`"
                helper="account_plan_security_payments"
            />
            <BlockInfo
                v-if="blocksFilters.indexOf('account_planed_bond_close_income') !== -1"
                title="Доход 2. План при погашении облигации"
                :showFull="currentFilter"
                :value="infoAccountParam('planed_bond_close_income')"
                :values="infoAccountParams('planed_bond_close_income')"
                valueToPrice
                valueUseSign
                :subvalue="infoAccountParam('planed_bond_close_income_percent')"
                :subvalues="infoAccountParams('planed_bond_close_income_percent')"
                subvalueToPercent
                classMain="account__info-block-item"
                :classColor="`text-${infoAccountParam('planed_bond_close_income') >= 0 ? 'success' : 'danger'}`"
                helper="account_planed_bond_close_income"
            />
            <BlockInfo
                v-if="blocksFilters.indexOf('account_planed_bond_repayment_sum') !== -1"
                title="Сумма при погашении"
                :showFull="currentFilter"
                :value="infoAccountParam('planed_bond_repayment_sum')"
                :values="infoAccountParams('planed_bond_repayment_sum')"
                valueToPrice
                valueUseSign
                classMain="account__info-block-item"
                :classColor="`text-${infoAccountParam('planed_bond_repayment_sum') >= 0 ? 'success' : 'danger'}`"
                helper="account_planed_bond_repayment_sum"
            />
            <BlockInfo
                v-if="blocksFilters.indexOf('account_info') !== -1"
                :title="portfolio.trust_comment || portfolio.comment"
                :value="`${(portfolio.last_deal_date) ? $helpers.formatDate(new Date(portfolio.last_deal_date), 'DD.MM.YYYY') : '—'}`"
                valueLabel="Дата последней загруженной сделки:"
                reverse
                classMain="account__info-block-item"
                :classColorValue="checkLastDealDate ? 'text-danger' : ''"
                classTitle="small"
                classColor=""
                helper="account_info"
            />
            <BlockInfo
                v-if="blocksFilters.indexOf('account_assets_average_annual_income') !== -1"
                title="Среднегодовая прибыль ЦБ на БС"
                :value="infoAccountParam('assets_average_annual_income')"
                valueToPercent
                classMain="account__info-block-item"
                :classColorValue="`text-${infoAccountParam('assets_average_annual_income') >= 0 ? 'success' : 'danger'}`"
                helper="account_assets_average_annual_income"
            />
        </div>
    </template>
</template>

<script>
    import { blocksNames } from "@/settings/blocks";
    import { app } from "@/services";
    import BlockInfo from "@/components/block-info";
    import Helper from "@/components/helper";
    import ModalFilterBlocks from "@/components/modals/modal-filter-blocks";
    export default {
        components: {
            BlockInfo,
            Helper,
            ModalFilterBlocks
        },
        computed: {
            currencyCurrent() {
                return this.currencyList.filter(item => item.iso_name === this.$store.state.currencyCurrent.iso_name)[0];
            },
            userSettings() {
                return this.$store.state.user?.user_settings || {};
            },
            checkLastDealDate() {
                if (!this.portfolio.last_deal_date) return false;
                let date = new Date();
                date.setDate(date.getDate() - 1);
                date.setHours(0,0,0,0);
                let dateDeal = new Date(this.portfolio.last_deal_date);
                dateDeal.setHours(0,0,0,0);
                return (dateDeal < date) ? true : false;
            }
        },
        props: {
            portfolio: {
                type: Object,
                default() { return {}; }
            },
            currentFilter: {
                type: Boolean,
                default() { return false; }
            },
        },
        data() {
            return {
                blocksNames: blocksNames['accountInfo'],
                blocksFilters: [],
                currencyList: [...this.$store.state.currencyList],
                showModalFilterBlocks: false,
                showLoaderSending: false,
            }
        },
        created() {
            this.currencyListDefault();
        },
        mounted() {
            this.blocksFilters = [];
            Object.keys(this.blocksNames).map((key) => {
                if (!this.blocksNames[key].hide) {
                    if (this.userSettings.accounts?.[this.portfolio.id]?.['accountInfo']) {
                        if (this.userSettings.accounts[this.portfolio.id]['accountInfo'].indexOf(key) !== -1 || this.userSettings.accounts[this.portfolio.id]['accountInfo'].indexOf(this.blocksNames[key].synonim) !== -1) this.blocksFilters.push(key);
                    //} else if (this.userSettings.tableHeader?.['accountInfo']) {
                    //    if (this.userSettings.tableHeader['accountInfo'].indexOf(key) !== -1 || this.userSettings.tableHeader['accountInfo'].indexOf(this.tableHeader[key].synonim) !== -1) this.blocksFilters.push(key);
                    } else {
                        if (!this.blocksNames[key].hideDefault) this.blocksFilters.push(key);
                    }
                }
            });
            this.currencyList = this.currencyList.sort((a, b) => {
                if (a.default && !b.default)
                    return -1;
                if (!a.default && b.default)
                    return 1;
                return 0;
            });
        },
        methods: {
            currencyListDefault() {
                if (!this.$store.state.currencyCurrent) {
                    this.showLoaderSending = true;
                    app.getCurrency(1).then(res => {
                        let currencies = [res];
                        this.$store.dispatch('setCurrencyList', currencies);
                        this.$store.dispatch('setCurrency', currencies[0]);
                        this.showLoaderSending = false;
                    });
                } else {
                    this.showLoaderSending = false;
                }
            },
            infoAccountParam(param) {
                if (this.portfolio.summary) {
                    return this.portfolio.summary[param] || 0;
                }
                return null;
            },
            infoAccountParams(param) {
                if (this.portfolio.currency_summary) {
                    let data = {};
                    Object.keys(this.portfolio.currency_summary).forEach(key => {
                        data[key] = this.portfolio.currency_summary[key][param];
                    });
                    return data;
                }
                return {};
            },
            changeFilterBlocks(filter) {
                //this.$emit('changeFilterTable', filter);
                this.blocksFilters = filter;
            },
            showFilterBlocks() {
                this.showModalFilterBlocks = true;
            },
            hideFilterBlocks(filter) {
                console.log('hideFilterBlocks', filter);
                //if (this.tableHeaderName) {
                    let settings = this.userSettings;
                    if (this.portfolio.id) {
                        if (!settings.accounts) settings.accounts = {};
                        if (!settings.accounts[this.portfolio.id]) settings.accounts[this.portfolio.id] = {};
                        settings.accounts[this.portfolio.id]['accountInfo'] = filter;
                    //} else {
                    //    if (!settings.tableHeader) settings.tableHeader = {};
                    //    settings.tableHeader[this.tableHeaderName] = filter;
                    }
                    app.updateUser({ user_settings: settings }).then(res => {
                        console.log(res);
                        delete res.currencies;
                        this.$store.dispatch('setUser', res);
                    }).catch(err => {
                        console.error(err);
                    });
                //}
                this.showModalFilterBlocks = false;
            },
        }
    };
</script>
