<template>
    <b-card
        class="bond-screener__filters mb-5 shadow"
        bodyClass="row"
    >
        <template
            v-if="$store.state.mode === 'all'"
        >
            <pre>{{ formData }}</pre>
        </template>
        <div
            v-if="userSettings"
            class="bond-screener__filters-presets col-12 gap-3"
        >
            <b-dropdown
                variant="light"
                class="bond-screener__filters-presets-select"
                toggleClass="shadow"
                menuClass="mt-2"
                no-caret
            >
                <template #button-content>
                    <span>{{ selectedFilterKey ? userSettings?.filters?.bondScreener?.[selectedFilterKey]?.name : 'Выбрать или создать фильтр' }}</span>
                    <b-icon-chevron-down class="ms-1" />
                </template>
                <b-dropdown-item-button
                    :active="!selectedFilterKey"
                    @click.prevent="clearFilterTableData"
                >
                    Фильтр по умолчанию
                </b-dropdown-item-button>
                <b-dropdown-item-button
                    variant=""
                    @click.prevent="showFilterAdd()"
                >
                    Создать новый фильтр...
                </b-dropdown-item-button>
                <b-dropdown-divider v-if="Object.keys(userSettings?.filters?.bondScreener || {}).length" />
                <b-dropdown-item-button
                    v-for="(item, key) in userSettings?.filters?.bondScreener"
                    :key="`filter-${key}`"
                    :active="+selectedFilterKey === +key"
                    @click.prevent="selectFilter(key)"
                >
                    {{ item.name }}
                </b-dropdown-item-button>
            </b-dropdown>
            <b-button
                v-if="selectedFilterKey && canSaveFilter"
                variant="light-primary"
                class="me-3 shadow"
                size=""
                pill
                @click.stop="saveFilter"
            >
                <b-icon-floppy class="me-1" />
                Сохранить изменения
            </b-button>
            <a
                v-if="selectedFilterKey"
                href="#"
                class="m--underline"
                @click.prevent="showFilterDelete()"
            >
                <b-icon-x-lg class="me-1 text-danger" />Удалить
            </a>
            <b-button
                variant="light-primary"
                class="ms-auto shadow"
                size=""
                pill
                @click.prevent="toggleShowFilters"
            >
                {{ showFilters ? 'Скрыть параметры поиска' : 'Показать параметры поиска' }}
            </b-button>

        </div>
        <template v-if="showFilters">
            <div class="col-12 mt-2">
                <hr>
            </div>
            <div class="col-12 col-xxl-5 mb-auto">
                <div class="row">
                    <b-form-group
                        id="input-group-price-percent"
                        label="Цена, % от номинала"
                        class="col-5 mt-auto"
                    >
                        <div class="row">
                            <div class="col-6 pe-2">
                                <input
                                    id="input-price-percent"
                                    v-maska
                                    data-maska="0.99"
                                    data-maska-tokens="0:\d:multiple|9:\d:optional"
                                    v-model="formData.price_percent_min"
                                    type="text"
                                    placeholder="от"
                                    class="form-control form-control-sm shadow-sm m--no-pill"
                                />
                            </div>
                            <div class="col-6 ps-1 pe-3">
                                <input
                                    id="input-price-percent"
                                    v-maska
                                    data-maska="0.99"
                                    data-maska-tokens="0:\d:multiple|9:\d:optional"
                                    v-model="formData.price_percent_max"
                                    type="text"
                                    placeholder="до"
                                    class="form-control form-control-sm shadow-sm m--no-pill"
                                />
                            </div>
                        </div>
                    </b-form-group>
                    <b-form-group
                        id="input-group-price"
                        label="Текущая цена"
                        class="col-7 mt-auto"
                    >
                        <div class="row">
                            <div class="col-6 pe-2">
                                <input
                                    id="input-price"
                                    v-maska
                                    data-maska="0.99"
                                    data-maska-tokens="0:\d:multiple|9:\d:optional"
                                    v-model="formData.price_min"
                                    type="text"
                                    placeholder="от"
                                    class="form-control form-control-sm shadow-sm m--no-pill"
                                />
                            </div>
                            <div class="col-6 ps-1 pe-3">
                                <input
                                    id="input-price"
                                    v-maska
                                    data-maska="0.99"
                                    data-maska-tokens="0:\d:multiple|9:\d:optional"
                                    v-model="formData.price_max"
                                    type="text"
                                    placeholder="до"
                                    class="form-control form-control-sm shadow-sm m--no-pill"
                                />
                            </div>
                        </div>
                    </b-form-group>

                    <b-form-group
                        id="input-group-year-income"
                        class="col-5 mt-auto"
                    >
                        <template #label>
                            Доход 1, %
                            <Helper
                                id="popover-screener-input-year-income"
                                helper="screener_input_year_income"
                                classModifier="m--dark"
                            />
                        </template>
                        <div class="row">
                            <div class="col-6 pe-2">
                                <input
                                    id="input-year-income"
                                    v-maska
                                    data-maska="0.99"
                                    data-maska-tokens="0:\d:multiple|9:\d:optional"
                                    v-model="formData.year_income_min"
                                    type="text"
                                    placeholder="от"
                                    class="form-control form-control-sm shadow-sm m--no-pill"
                                />
                            </div>
                            <div class="col-6 ps-1 pe-3">
                                <input
                                    id="input-year-income-max"
                                    v-maska
                                    data-maska="0.99"
                                    data-maska-tokens="0:\d:multiple|9:\d:optional"
                                    v-model="formData.year_income_max"
                                    type="text"
                                    placeholder="до"
                                    class="form-control form-control-sm shadow-sm m--no-pill"
                                />
                            </div>
                        </div>
                    </b-form-group>

                    <b-form-group
                        id="input-group-maturity-years"
                        label="Срок до погашения, лет"
                        class="col-7 mt-auto"
                    >
                        <div class="row">
                            <div class="col-6 pe-2">
                                <input
                                    id="input-maturity-years"
                                    v-maska
                                    data-maska="0.99"
                                    data-maska-tokens="0:\d:multiple|9:\d:optional"
                                    v-model="formData.maturity_years_min"
                                    type="text"
                                    placeholder="от"
                                    class="form-control form-control-sm shadow-sm m--no-pill"
                                />
                            </div>
                            <div class="col-6 ps-1 pe-3">
                                <input
                                    id="input-maturity-years-max"
                                    v-maska
                                    data-maska="0.99"
                                    data-maska-tokens="0:\d:multiple|9:\d:optional"
                                    v-model="formData.maturity_years_max"
                                    type="text"
                                    placeholder="до"
                                    class="form-control form-control-sm shadow-sm m--no-pill"
                                />
                            </div>
                        </div>
                    </b-form-group>

                    <b-form-group
                        id="input-group-close-income"
                        class="col-5 mt-auto"
                    >
                        <template #label>
                            Доход 2, %
                            <Helper
                                id="popover-screener-input-close-income"
                                helper="screener_input_close_income"
                                classModifier="m--dark"
                            />
                        </template>
                        <div class="row">
                            <div class="col-6 pe-2">
                                <input
                                    id="input-close-income"
                                    v-maska
                                    data-maska="0.99"
                                    data-maska-tokens="0:\d:multiple|9:\d:optional"
                                    v-model="formData.close_income_min"
                                    type="text"
                                    placeholder="от"
                                    class="form-control form-control-sm shadow-sm m--no-pill"
                                />
                            </div>
                            <div class="col-6 ps-1 pe-3">
                                <input
                                    id="input-close-income-max"
                                    v-maska
                                    data-maska="0.99"
                                    data-maska-tokens="0:\d:multiple|9:\d:optional"
                                    v-model="formData.close_income_max"
                                    type="text"
                                    placeholder="до"
                                    class="form-control form-control-sm shadow-sm m--no-pill"
                                />
                            </div>
                        </div>
                    </b-form-group>

                    <b-form-group
                        id="input-group-duration"
                        label="Дата начала торгов"
                        class="col-7 mt-auto"
                    >
                        <div class="row">
                            <div class="col-6 pe-2">
                                <VueDatePicker
                                    v-model="formData.issue_date_after"
                                    model-type="yyyy-MM-dd"
                                    format="dd.MM.yyyy"
                                    locale="ru"
                                    select-text="Выбрать"
                                    cancel-text="Отменить"
                                    :auto-apply="true"
                                    :close-on-auto-apply="false"
                                    :year-range="[2000, 2050]"
                                    :prevent-min-max-navigation="false"
                                    text-input
                                    :enable-time-picker="false"
                                    :month-change-on-scroll="true"
                                    :clearable="true"
                                    :hide-input-icon="false"
                                    allow-prevent-default
                                    @update:model-value="changeDate"
                                >
                                    <template #dp-input="{ value, onInput, onEnter, onTab }">
                                        <input
                                            v-maska
                                            data-maska="##.##.####"
                                            data-maska-eager
                                            class="form-control form-control-sm shadow-sm m--no-pill"
                                            type="text"
                                            :value="value"
                                            @keyup="onInput"
                                            @keydown.enter="onEnter"
                                            @keydown.tab="onTab"
                                        />
                                    </template>
                                </VueDatePicker>
                            </div>
                            <div class="col-6 ps-1 pe-3">
                                <VueDatePicker
                                    v-model="formData.issue_date_before"
                                    model-type="yyyy-MM-dd"
                                    format="dd.MM.yyyy"
                                    locale="ru"
                                    select-text="Выбрать"
                                    cancel-text="Отменить"
                                    :auto-apply="true"
                                    :close-on-auto-apply="false"
                                    :year-range="[2000, 2050]"
                                    :prevent-min-max-navigation="false"
                                    text-input
                                    :enable-time-picker="false"
                                    :month-change-on-scroll="true"
                                    :clearable="true"
                                    :hide-input-icon="false"
                                    allow-prevent-default
                                    @update:model-value="changeDate"
                                >
                                    <template #dp-input="{ value, onInput, onEnter, onTab }">
                                        <input
                                            v-maska
                                            data-maska="##.##.####"
                                            data-maska-eager
                                            class="form-control form-control-sm shadow-sm m--no-pill"
                                            type="text"
                                            :value="value"
                                            @keyup="onInput"
                                            @keydown.enter="onEnter"
                                            @keydown.tab="onTab"
                                        />
                                    </template>
                                </VueDatePicker>
                            </div>
                        </div>
                    </b-form-group>

                    <b-form-group
                        id="input-group-total-income"
                        class="col-5 mt-auto"
                    >
                        <template #label>
                            Общий доход, %
                            <Helper
                                id="popover-screener-input-total-income"
                                helper="screener_input_total_income"
                                classModifier="m--dark"
                            />
                        </template>
                        <div class="row">
                            <div class="col-6 pe-2">
                                <input
                                    id="input-total-income"
                                    v-maska
                                    data-maska="0.99"
                                    data-maska-tokens="0:\d:multiple|9:\d:optional"
                                    v-model="formData.total_income_min"
                                    type="text"
                                    placeholder="от"
                                    class="form-control form-control-sm shadow-sm m--no-pill"
                                />
                            </div>
                            <div class="col-6 ps-1 pe-3">
                                <input
                                    id="input-total-income-max"
                                    v-maska
                                    data-maska="0.99"
                                    data-maska-tokens="0:\d:multiple|9:\d:optional"
                                    v-model="formData.total_income_max"
                                    type="text"
                                    placeholder="до"
                                    class="form-control form-control-sm shadow-sm m--no-pill"
                                />
                            </div>
                        </div>
                    </b-form-group>

                    <b-form-group
                        id="input-group-coupon-frequency"
                        label="Частота выплаты купонов в год"
                        class="col-7 mt-auto"
                    >
                        <div class="row">
                            <div class="col-6 pe-2">
                                <input
                                    id="input-coupon-frequency"
                                    v-maska
                                    data-maska="0.99"
                                    data-maska-tokens="0:\d:multiple|9:\d:optional"
                                    v-model="formData.coupon_frequency_min"
                                    type="text"
                                    placeholder="от"
                                    class="form-control form-control-sm shadow-sm m--no-pill"
                                />
                            </div>
                            <div class="col-6 ps-1 pe-3">
                                <input
                                    id="input-coupon-frequency-max"
                                    v-maska
                                    data-maska="0.99"
                                    data-maska-tokens="0:\d:multiple|9:\d:optional"
                                    v-model="formData.coupon_frequency_max"
                                    type="text"
                                    placeholder="до"
                                    class="form-control form-control-sm shadow-sm m--no-pill"
                                />
                            </div>
                        </div>
                    </b-form-group>

                    <b-form-group
                        id="input-group-minimum-coupon-rate"
                        class="col-5 mt-auto"
                    >
                        <template #label>
                            Мин. ставка ПК 1, %
                            <Helper
                                id="popover-screener-input-minimum-coupon-rate"
                                helper="screener_input_minimum_coupon_rate"
                                classModifier="m--dark"
                            />
                        </template>
                        <input
                            id="input-minimum-coupon-rate"
                            v-maska
                            data-maska="0.99"
                            data-maska-tokens="0:\d:multiple|9:\d:optional"
                            v-model="formData.minimum_coupon_rate"
                            type="text"
                            :disabled="!formData.floater_type?.length || formData.floater_type?.indexOf('known') === -1"
                            placeholder="от"
                            class="form-control form-control-sm shadow-sm m--no-pill"
                        />
                    </b-form-group>

                    <b-form-group
                        id="input-group-spread-amount"
                        class="col-7 mt-auto"
                    >
                        <template #label>
                            Спред ПК 3, %
                            <Helper
                                id="popover-screener-input-spread-amount"
                                helper="screener_input_spread_amount"
                                classModifier="m--dark"
                            />
                        </template>
                        <div class="row">
                            <div class="col-6 pe-2">
                                <input
                                    id="input-spread-amount"
                                    v-maska
                                    data-maska="0.99"
                                    data-maska-tokens="0:\d:multiple|9:\d:optional"
                                    v-model="formData.spread_amount_min"
                                    type="text"
                                    :disabled="!formData.floater_type?.length || formData.floater_type?.indexOf('spreading') === -1"
                                    placeholder="от"
                                    class="form-control form-control-sm shadow-sm m--no-pill"
                                />
                            </div>
                            <div class="col-6 ps-1 pe-3">
                                <input
                                    id="input-spread-amount-max"
                                    v-maska
                                    data-maska="0.99"
                                    data-maska-tokens="0:\d:multiple|9:\d:optional"
                                    v-model="formData.spread_amount_max"
                                    type="text"
                                    :disabled="!formData.floater_type?.length || formData.floater_type?.indexOf('spreading') === -1"
                                    placeholder="до"
                                    class="form-control form-control-sm shadow-sm m--no-pill"
                                />
                            </div>
                        </div>
                    </b-form-group>

                    <b-form-group
                        id="input-group-rating"
                        class="col-12 mt-auto"
                    >
                        <template #label>
                            Рейтинг эмитента
                            <Helper
                                id="popover-screener-input-rating"
                                helper="screener_input_rating"
                                classModifier="m--dark"
                            />
                        </template>
                        <b-form-checkbox-group
                            id="checkbox-rating"
                            v-model="formData.rating"
                            name="rating"
                            :options="ratingList"
                            class="ps-1"
                        />
                    </b-form-group>
                </div>
            </div>
            <div class="col-12 col-xxl-7 mb-auto">
                <div class="row">
                    <div class="col-4">
                        <b-form-group
                            label="Валюта номинала"
                        >
                            <!--div class="mb-2">
                                <b-form-checkbox
                                    v-model="formData.currency_all"
                                    :value="true"
                                    switch
                                    @change="selectCurrencyAll"
                                >
                                    Выбрать все
                                </b-form-checkbox>
                            </div-->
                            <b-form-radio-group
                                id="checkbox-currency"
                                v-model="formData.currency"
                                name="currency"
                                :options="bondCurrencyList"
                                @change="selectCurrency"
                            />
                        </b-form-group>
                        <b-form-group
                            label="Особенности номинала"
                        >
                            <!--div class="mb-2">
                                <b-form-checkbox
                                    v-model="formData.feature_all"
                                    :value="true"
                                    switch
                                    @change="selectFeatureAll"
                                >
                                    Выбрать все
                                </b-form-checkbox>
                            </div-->
                            <b-form-checkbox-group
                                id="checkbox-nominal-feature"
                                v-model="formData.nominal_feature"
                                name="nominal_feature"
                                :options="bondNominalFeatureList"
                                stacked
                                @change="selectFeature"
                            />
                        </b-form-group>
                        <b-form-group
                            label="Особенности купона"
                        >
                            <!--div class="mb-2">
                                <b-form-checkbox
                                    v-model="formData.feature_all"
                                    :value="true"
                                    switch
                                    @change="selectFeatureAll"
                                >
                                    Выбрать все
                                </b-form-checkbox>
                            </div-->
                            <b-form-checkbox-group
                                id="checkbox-coupon-feature"
                                v-model="formData.coupon_feature"
                                name="coupon_feature"
                                :options="bondCouponFeatureList"
                                stacked
                                @change="selectFeature('coupon')"
                            />
                            <b-form-checkbox-group
                                id="checkbox-coupon-floating"
                                v-model="formData.floater_type"
                                name="coupon_feature"
                                :options="floaterTypeList"
                                :disabled="!formData.coupon_feature || formData.coupon_feature?.indexOf('floating') === -1"
                                stacked
                                @change="selectFeature('floater')"
                            />
                        </b-form-group>
                        <b-form-group
                            label="Прошедшие размещения"
                        >
                            <a
                                href="#"
                                :class="['me-2 text-decoration-underline', formData.preset_date_range?.deltaDays === 7 && 'm--active']"
                                @click.prevent="setFilterDateRange('issue_date_after', 'issue_date_before', 7)"
                            >
                                За 7 дней
                            </a>
                            <a
                                href="#"
                                :class="['me-2 text-decoration-underline', formData.preset_date_range?.deltaDays === 30 && 'm--active']"
                                @click.prevent="setFilterDateRange('issue_date_after', 'issue_date_before', 30)"
                            >
                                За 30 дней
                            </a>
                        </b-form-group>
                    </div>

                    <div class="col-4">
                        <b-form-group
                            label="Вид ценной бумаги"
                        >
                            <div class="mb-2">
                                <b-form-checkbox
                                    v-model="formData.security_type_all"
                                    :value="true"
                                    switch
                                    @change="selectTypeAll"
                                >
                                    Выбрать все
                                </b-form-checkbox>
                            </div>
                            <b-form-checkbox-group
                                id="checkbox-type"
                                v-model="formData.security_type"
                                name="security_type"
                                :options="bondTypeList"
                                stacked
                                @change="selectType"
                            />
                        </b-form-group>
                        <b-form-group
                            label=""
                        >
                            <div class="mb-2">
                                <b-form-checkbox
                                    v-model="formData.has_price"
                                    :value="true"
                                >
                                    Только облигации с ценой
                                </b-form-checkbox>
                            </div>
                        </b-form-group>
                    </div>

                    <div class="col-4">
                        <b-form-group
                            id="input-group-tax"
                            label="Налоговая ставка, %"
                        >
                            <template #label>
                                Налоговая ставка, %
                                <Helper
                                    id="popover-screener-input-tax"
                                    helper="screener_input_tax"
                                    classModifier="m--dark"
                                />
                            </template>
                            <input
                                id="input-tax"
                                v-maska
                                data-maska="00"
                                data-maska-tokens="0:\d"
                                v-model="formData.tax"
                                type="text"
                                placeholder=""
                                class="form-control form-control-sm shadow-sm m--no-pill"
                            />
                        </b-form-group>
                        <b-form-group
                            label="Только для квалифицированных"
                        >
                            <b-form-radio v-model="formData.qualified" name="qualified" :value="true">Да</b-form-radio>
                            <b-form-radio v-model="formData.qualified" name="qualified" :value="false">Нет</b-form-radio>
                            <b-form-radio v-model="formData.qualified" name="qualified" :value="-1">Любые</b-form-radio>
                        </b-form-group>
                        <b-form-group
                            label="Дата оферты на MOEX"
                        >
                            <b-form-radio v-model="formData.has_offer" name="has_offer" :value="true">Да</b-form-radio>
                            <b-form-radio v-model="formData.has_offer" name="has_offer" :value="false">Нет</b-form-radio>
                            <b-form-radio v-model="formData.has_offer" name="has_offer" :value="-1">Не важно</b-form-radio>
                        </b-form-group>
                        <b-form-group>
                            <template #label>
                                Дефолт
                                <Helper
                                    id="popover-screener-checkbox-has-default"
                                    helper="screener_checkbox_has_default"
                                    classModifier="m--dark"
                                />
                            </template>
                            <div class="mb-2">
                                <b-form-checkbox
                                    v-model="formData.has_default_all"
                                    :value="true"
                                    switch
                                    @change="selectHasDefaultAll"
                                >
                                    Выбрать все
                                </b-form-checkbox>
                            </div>
                            <b-form-checkbox-group
                                id="checkbox-type"
                                v-model="formData.has_default"
                                name="has_default"
                                :options="hasDefaultList"
                                stacked
                                @change="selectHasDefault"
                            />
                        </b-form-group>
                    </div>
                </div>
            </div>
            <div class="col-12 pt-2">
                <b-button
                    variant="primary"
                    class="col-auto me-3"
                    size=""
                    pill
                    @click.stop="changeFilterTableData"
                >
                    Применить фильтр
                </b-button>
                <b-button
                    v-if="useFilters"
                    variant="outline-primary"
                    class="col-auto"
                    size=""
                    pill
                    @click.stop="clearFilterTableData"
                >
                    Сбросить фильтр
                </b-button>
            </div>
        </template>
        <ModalFilterAdd
            :show="showModalFilterAdd"
            filterInterface="bondScreener"
            :filterData="formData"
            @hideModal="hideFilterAdd"
        />
        <ModalFilterDelete
            :show="showModalFilterDelete"
            filterInterface="bondScreener"
            :selectedFilterKey="selectedFilterKey"
            :filterData="formData"
            @hideModal="hideFilterDelete"
        />
    </b-card>
</template>

<script>
    import { app } from "@/services";
    import { defaultBondScreenerFilter } from "@/settings/tables";
    import Helper from "@/components/helper";
    import ModalFilterAdd from "@/components/modals/modal-filter-add";
    import ModalFilterDelete from "@/components/modals/modal-filter-delete";

    export default {
        emits: ['changeFilterTableData', 'clearFilterTableData'],
        components: {
            Helper,
            ModalFilterAdd,
            ModalFilterDelete,
        },
        props: {
            classButton: {
                type: String,
                default() { return null; }
            },
            tableHeader: {
                type: Object,
                default() { return {}; }
            },
            tableHeaderGroups: {
                type: Array,
                default() { return []; }
            },
            currentFilterKey: {
                type: [String, Number],
                default() { return null; }
            },
            tableDataFilters: {
                type: Object,
                default() { return {}; }
            },
            useFilters: {
                type: Boolean,
                default() { return false; }
            },
            showExtendedFilters: {
                type: Boolean,
                default() { return false; }
            },
        },
        data() {
            return {
                showFilters: this.showExtendedFilters,
                dateRange: [],
                bondCurrencyList: [],
                bondNominalFeatureList: [],
                bondCouponFeatureList: [],
                bondTypeList: [],
                ratingList: [],
                hasDefaultList: [],
                floaterTypeList: [],
                defaultBondScreenerFilter: defaultBondScreenerFilter,
                selectedFilterKey: this.currentFilterKey,
                canSaveFilter: false,
                formData: this.tableDataFilters,
                showModalFilterAdd: false,
                showModalFilterDelete: false,
                showLoaderSending: false
            }
        },
        computed: {
            userSettings() {
                return this.$store.state.user?.user_settings || {};
            },
        },
        watch: {
            showExtendedFilters: {
                //immediate: true,
                handler() {
                    this.showFilters = this.showExtendedFilters;
                }
            },
            useFilters: {
                //immediate: true,
                handler() {
                    if (!this.useFilters) {
                        //this.selectCurrencyAll();
                        //this.selectFeatureAll();
                        this.selectTypeAll();
                        this.selectHasDefaultAll();
                        this.formData = this.tableDataFilters;
                    }
                }
            },
            dateRange: {
                //immediate: true,
                handler() {
                    //if (this.dateRange[0] && this.dateRange[1]) {
                        console.log('dateRange', this.dateRange);
                        this.$nextTick(() => {
                            this.formData.issue_date_after = this.dateRange[0];
                            this.formData.issue_date_before = this.dateRange[1];
                        });
                    //}
                }
            },
            formData: {
                //immediate: true,
                deep: true,
                handler() {
                    console.log('formData');
                    this.canSaveFilter = true;
                    //if (this.formData.floater_type?.indexOf('known') === -1) delete this.formData.minimum_coupon_rate;
                }
            },
        },
        mounted() {
            //this.selectCurrencyAll();
            this.getCurrencyList();
            this.getNominalFeatureList();
            this.getCouponFeatureList();
            this.getTypeList();
            this.getRatingList();
            this.getFloaterTypeList();
            this.getHasDefaultList();
            this.canSaveFilter = false;

            if (this.formData.preset_date_range) {
                let from = this.formData.preset_date_range.from;
                let to = this.formData.preset_date_range.to;
                let deltaDays = this.formData.preset_date_range.deltaDays;
                this.setFilterDateRange(from, to, deltaDays);
            }
        },
        methods: {
            getCurrencyList() {
                this.showLoaderSending = true;
                app.getBondCurrencies().then(res => {
                    if (!res.error) this.bondCurrencyList = res.map(item => { return { text: item.name, value: item.key } });
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                    this.bondCurrencyList = [];
                });
            },
            getNominalFeatureList() {
                this.showLoaderSending = true;
                app.getBondNominalFeatures().then(res => {
                    if (!res.error) this.bondNominalFeatureList = res.map(item => { return { text: item.name, value: item.key } });
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                    this.bondNominalFeatureList = [];
                });
            },
            getCouponFeatureList() {
                this.showLoaderSending = true;
                app.getBondCouponFeatures().then(res => {
                    if (!res.error) this.bondCouponFeatureList = res.map(item => { return { text: item.name, value: item.key } });
                    // РЫБА
                    /*
                    this.bondCouponFeatureList = [...this.bondCouponFeatureList, ...[
                        { text: 'ПК 1. Известный', value: '', disabled: true },
                        { text: 'ПК 2. На усмотрение эмитента', value: '', disabled: true },
                        { text: 'ПК 3. Флоатер', value: '', disabled: true }
                    ]]
                    */
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                    this.bondCouponFeatureList = [];
                });
            },
            getTypeList() {
                this.showLoaderSending = true;
                app.getBondTypes().then(res => {
                    if (!res.error) this.bondTypeList = res.map(item => { return { text: item.name, value: item.key } });
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                    this.bondTypeList = [];
                });
            },
            getRatingList() {
                this.showLoaderSending = true;
                app.getBondRatings().then(res => {
                    if (!res.error) this.ratingList = res.map(item => { return { text: item.name, value: item.key } });
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                    this.ratingList = [];
                });
            },
            getHasDefaultList() {
                this.showLoaderSending = true;
                app.getBondHasDefault().then(res => {
                    if (!res.error) this.hasDefaultList = res.map(item => { return { text: item.name, value: item.key } });
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                    this.hasDefaultList = [];
                });
            },
            getFloaterTypeList() {
                this.showLoaderSending = true;
                app.getBondFloaterTypes().then(res => {
                    if (!res.error) this.floaterTypeList = res.map(item => { return { text: item.name, value: item.key } });
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                    this.floaterTypeList = [];
                });
            },
            toggleShowFilters() {
                //this.showFilters = !this.showFilters;
                this.$emit('changeShowFilters', !this.showFilters);
            },
            selectCurrencyAll() {
                this.formData.currency_all ? this.formData.currency = this.bondCurrencyList.map(item => { return item.value; }) : delete this.formData.currency;
            },
            selectCurrency() {
                //this.formData.currency_all = (this.formData.currency.length === this.bondCurrencyList.length) ? true : false;
            },
            selectFeatureAll() {
                this.formData.feature_all ? this.formData.feature = this.bondFeatureList.map(item => { return item.value; }) : delete this.formData.feature;
            },
            selectFeature(type = null) {
                if (type === 'coupon') {
                    if (this.formData.coupon_feature.indexOf('floating') === -1) delete this.formData.floater_type;
                    if (this.formData.coupon_feature.indexOf('fixed') !== -1) delete this.formData.floater_type;
                }
                if (type === 'floater') {
                    if (this.formData.floater_type?.length) {
                        let idx = this.formData.coupon_feature.indexOf('fixed')
                        if (idx !== -1) this.formData.coupon_feature.splice(idx, 1)
                    }
                }
                //this.formData.feature_all = (this.formData.feature.length === this.bondFeatureList.length) ? true : false;
            },
            selectTypeAll() {
                this.formData.security_type_all ? this.formData.security_type = this.bondTypeList.map(item => { return item.value; }) : delete this.formData.security_type;
            },
            selectType() {
                this.formData.security_type_all = (this.formData.security_type.length === this.bondTypeList.length) ? true : false;
            },
            selectHasDefaultAll() {
                this.formData.has_default_all ? this.formData.has_default = this.hasDefaultList.map(item => { return item.value; }) : delete this.formData.has_default;
            },
            selectHasDefault() {
                this.formData.has_default_all = (this.formData.has_default.length === this.hasDefaultList.length) ? true : false;
            },
            changeDate() {
                delete this.formData.preset_date_range;
            },
            setFilterDateRange(from, to, deltaDays) {
                let date = new Date();
                date.setDate(date.getDate() - deltaDays);
                this.formData[from] = this.$helpers.formatDate(date, 'YYYY-MM-DD');
                this.formData[to] = this.$helpers.formatDate(new Date(), 'YYYY-MM-DD');
                this.formData.preset_date_range = {
                    from: from,
                    to: to,
                    deltaDays: deltaDays
                };
            },
            changeFilterTableData() {
                this.$emit('changeFilterTableData', this.formData);
            },
            clearFilterTableData() {
                this.selectedFilterKey = null;
                let settings = this.userSettings;
                delete settings.filters?.bondScreener?.current;
                this.showLoaderSending = true;
                app.updateUser({ user_settings: settings }).then(res => {
                    console.log(res);
                    delete res.currencies;
                    this.$store.dispatch('setUser', res);
                    this.showLoaderSending = false;
                }).catch(err => {
                    console.error(err);
                    this.showLoaderSending = false;
                });
                this.canSaveFilter = false;

                this.$emit('clearFilterTableData');
            },
            selectFilter(key) {
                this.selectedFilterKey = key;
                this.formData = this.userSettings?.filters?.bondScreener[key]?.filters;
                if (this.formData.preset_date_range) {
                    let from = this.formData.preset_date_range.from;
                    let to = this.formData.preset_date_range.to;
                    let deltaDays = this.formData.preset_date_range.deltaDays;
                    this.setFilterDateRange(from, to, deltaDays);
                }
                let settings = this.userSettings;
                settings.filters.bondScreener.current = key;
                this.showLoaderSending = true;
                app.updateUser({ user_settings: settings }).then(res => {
                    console.log(res);
                    delete res.currencies;
                    this.$store.dispatch('setUser', res);
                    this.showLoaderSending = false;
                }).catch(err => {
                    console.error(err);
                    this.showLoaderSending = false;
                });
                this.changeFilterTableData();
                this.$nextTick(() => {
                    this.canSaveFilter = false;
                });
            },
            saveFilter() {
                if (this.selectedFilterKey) {
                    let settings = this.userSettings;
                    settings.filters.bondScreener[this.selectedFilterKey].filters = this.formData;
                    this.showLoaderSending = true;
                    app.updateUser({ user_settings: settings }).then(res => {
                        console.log(res);
                        delete res.currencies;
                        this.$store.dispatch('setUser', res);
                        this.showLoaderSending = false;
                    }).catch(err => {
                        console.error(err);
                        this.showLoaderSending = false;
                    });
                    this.canSaveFilter = false;
                }
            },
            showFilterAdd() {
                this.showModalFilterAdd = true;
            },
            hideFilterAdd(filterKey) {
                this.showModalFilterAdd = false;
                if (filterKey) {
                    this.showFilters = true;
                    this.selectFilter(filterKey);
                }
            },
            showFilterDelete() {
                this.showModalFilterDelete = true;
            },
            hideFilterDelete(refreshData) {
                this.showModalFilterDelete = false;
                if (refreshData) {
                    this.selectedFilterKey = null;
                    this.formData = this.defaultBondScreenerFilter;
                    this.clearFilterTableData();
                }
            },
        }
    };
</script>
